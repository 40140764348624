<template>
  <v-dialog
    v-model="isVisible"
    max-width="378"
    content-class="bg-silver text-center"
  >
    <div class="text-right">
      <button @click.stop="close" class="btn-close bg-primary">
        <div class="btn-close__icon" />
      </button>
    </div>
    <div class="px-8">
      <h2 class="mt-n7 mb-5 text-heading --prm-light">{{ $t('common.numberPeople') }}</h2>
      <div class="text-left text-title --c-prm --w-bold">{{ $t('common.adult') }}</div>
      <v-col cols="6" class="pa-0 mt-2">
        <v-row class="ma-0">
          <v-col cols="7" class="pa-0">
            <v-select
              :items="items"
              v-model="selectedAdults"
              solo
              flat
              height="60"
              hide-details
              class="rounded-lg"
            />
          </v-col>
          <v-col
            cols="3"
            class="pa-0 text-left pl-2 text-sub-title --w-medium --c-light-gray"
            align-self="end"
          >
            <span>{{ $t('common.firstName') }}</span>
          </v-col>
        </v-row>
      </v-col>
      <h3 class="text-left text-title --c-prm --w-bold mt-5">{{ $t('common.child') }}</h3>
      <v-row class="ma-0">
        <v-col cols="6" class="pa-0 mt-2">
          <v-row class="ma-0">
            <v-col cols="7" class="pa-0">
              <h3 class="text-left text-sub-title --w-medium --c-light-gray mb-2">
                {{ $t('common.sleepTogether') }}
              </h3>
              <v-select
                :items="items"
                v-model="selectedBedShare"
                solo
                flat
                height="60"
                hide-details
                class="rounded-lg"
              />
            </v-col>
            <v-col
              cols="3"
              class="pa-0 text-left pl-2 text-sub-title --w-medium --c-light-gray"
              align-self="end"
            >
              <span>{{ $t('common.firstName') }}</span></v-col
            >
          </v-row>
        </v-col>
        <v-col cols="6" class="pa-0 mt-2">
          <v-row class="ma-0">
            <v-col cols="7" class="pa-0">
              <h3 class="text-left text-sub-title --w-medium --c-light-gray mb-2">
                {{ $t('common.noSleepTogether') }}
              </h3>
              <v-select
                :items="items"
                v-model="selectedChildren"
                solo
                flat
                height="60"
                hide-details
                class="rounded-lg"
              />
            </v-col>
            <v-col
              cols="3"
              class="pa-0 text-left pl-2 text-sub-title --w-medium --c-light-gray"
              align-self="end"
            >
              <span>{{ $t('common.firstName') }}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <div>
        <v-btn
          elevation="0"
          color="btn-grad--prm"
          class="my-10"
          width="170"
          large
          rounded
          @click="confirm"
        >
          {{ $t('buttons.decision') }}
        </v-btn>
      </div>
    </div>
  </v-dialog>
</template>

<script>

export default {
  name: 'NumberGuest',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    adults: {
      type: Number,
      default: 1
    },
    children: {
      type: Number,
      default: 0
    },
    bedShare: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      selectedAdults: 1,
      selectedChildren: 0,
      selectedBedShare: 0
    }
  },
  watch: {
    visible(v) {
      if (v) {
        // when dialog is shown copy the data from props
        this.selectedAdults = this.adults
        this.selectedChildren = this.children
        this.selectedBedShare = this.bedShare
      }
    }
  },
  computed: {
    isVisible: {
      get () {
        return this.visible
      },
      set (value) {
        if (!value) {
          this.$emit('close')
        }
      }
    },
    items () {
      return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    }
  },
  methods: {
    confirm () {
      this.$emit('confirm', {
        adults: this.selectedAdults,
        children: this.selectedChildren,
        bedShare: this.selectedBedShare
      })
    },
    close () {
      this.selectedAdults = this.adults
      this.selectedChildren = this.children
      this.selectedBedShare = this.bedShare
      this.$emit('close')
    }
  }
}
</script>
