<template>
  <v-dialog
    v-model="isVisible"
    max-width="378"
    content-class="bg-silver text-center"
  >
    <div class="text-right">
      <button @click.stop="close" class="btn-close bg-primary">
        <div class="btn-close__icon" />
      </button>
    </div>
    <div class="px-6">
      <h2 class="mt-n7 mb-5 text-heading --prm-light">{{ $t('text.facility.select') }}</h2>
      <p class="text-medium --w-bold --c-light-gray">{{ $t('text.facility.pleaseSelect') }}</p>
      <v-row class="ma-0">
        <v-col cols="6" v-for="facility in facilities" :key="facility.id">
          <v-checkbox
            v-model="selectedFacilities"
            :value="facility.id"
            :label="facility.shortName"
            color="primary"
            class="normal-label"
            hide-details
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row class="mb-6">
        <v-col>
          <v-btn
            elevation="0"
            color="btn-grad--prm"
            @click="confirm"
            large
            rounded
            width="170"
          >
            {{ $t('buttons.completeSelection') }}
          </v-btn>
        </v-col>
        <v-col>
          <v-btn
            @click="checkAll"
            elevation="0"
            large
            rounded
            width="170"
            class="--prm"
          >{{ $t('buttons.checkAll') }}</v-btn>
        </v-col>
        <v-col>
          <v-btn
            @click="uncheck"
            elevation="0"
            large
            rounded
            width="170"
            class="--prm"
          >{{ $t('buttons.deselect') }}</v-btn>
        </v-col>
      </v-row>

    </div>
  </v-dialog>
</template>

<script>
export default {
  name: 'FacilitySelect',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    value: {
      type: Array,
      default: () => []
    }
  },
  async mounted () {
    await this.$doLoading(async () => {
      await this.$showGqlError(async () => {
        this.facilities = await this.$store.dispatch('getFacilityListForBooking')
      })
    })
  },
  data () {
    return {
      facilities: [],
      selectedFacilities: []
    }
  },
  computed: {
    isVisible: {
      get () {
        return this.visible
      },
      set (value) {
        if (!value) {
          this.$emit('close')
        }
      }
    }
  },
  methods: {
    confirm () {
      this.$emit('confirm', this.selectedFacilities.map(id => this.facilities.find(f => f.id === id)).filter(t => !!t))
    },
    uncheck () {
      this.selectedFacilities = []
    },
    checkAll () {
      this.selectedFacilities = this.facilities.map(f => f.id)
    },
    close () {
      this.selectedFacilities = this.value.map(f => f.id)
      this.$emit('close')
    }
  }
}
</script>
