<template>
<svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 43.646 43.646">
  <g id="Group_5239" data-name="Group 5239" transform="translate(-72 -332.5)">
    <path id="Path_7716" data-name="Path 7716" d="M77.4,172.135a21.319,21.319,0,1,0-5.255,34.008,21.514,21.514,0,0,0,7.417-6.379.633.633,0,0,0-1.024-.746,20.031,20.031,0,0,1-16.217,8.252,20.047,20.047,0,1,1,17.535-10.313.633.633,0,1,0,1.107.616A21.334,21.334,0,0,0,77.4,172.135Zm0,0" transform="translate(31.5 167.11)" :fill="color" stroke="rgba(0,0,0,0)" stroke-width="1"/>
    <path id="Path_7717" data-name="Path 7717" d="M228.438,242.69a.636.636,0,0,0,.508-.1,17.6,17.6,0,0,0-16.8-30.7.634.634,0,0,0-.314.89l16.2,29.592a.634.634,0,0,0,.412.313Zm-9.586-30.853a16.335,16.335,0,0,1,9.939,29.3l-4.428-8.09h5.6a.633.633,0,0,0,.633-.633v-5.5a6.476,6.476,0,0,0-.238-4.568,5.206,5.206,0,0,0-2.256-2.7,8.964,8.964,0,0,0-.939-2.439,5.282,5.282,0,0,0-4.738-2.876,4.519,4.519,0,0,0-2.873.923,3.867,3.867,0,0,0-1.347,2.185A4.046,4.046,0,0,0,220,221.856a5.27,5.27,0,0,0,4.9,3.044c1.714,0,2.37.71,2.622,1.265H220.6l-7.31-13.355a16.247,16.247,0,0,1,5.565-.973Zm6.053,11.8a3.946,3.946,0,0,1-3.8-2.435.634.634,0,0,0-.324-.364,2.736,2.736,0,0,1-1.333-3.111,2.8,2.8,0,0,1,2.984-2.122,4.02,4.02,0,0,1,3.622,2.211,7.62,7.62,0,0,1,.869,2.367.633.633,0,0,0,.384.491,3.782,3.782,0,0,1,1.887,2.158,5.5,5.5,0,0,1,.324,3.337h-.665c-.244-.924-1.08-2.531-3.944-2.531Zm4.429,3.8v4.346h-1.264v-4.346Zm-2.531,4.346h-3.134l-2.379-4.346H226.8Zm0,0" transform="translate(-125.028 126.152)" :fill="color" stroke="rgba(0,0,0,0)" stroke-width="1"/>
    <path id="Path_7718" data-name="Path 7718" d="M86.947,261.928a16.362,16.362,0,0,1,6.4-12.963l2.712,4.955a.633.633,0,1,0,1.111-.608l-3.059-5.59a.633.633,0,0,0-.919-.214,17.6,17.6,0,0,0,16.8,30.7.633.633,0,0,0,.314-.89l-11.922-21.78a.633.633,0,1,0-1.111.608l2.068,3.778H91.066a.633.633,0,0,0-.633.634v5.612a.633.633,0,0,0,.633.633H103.1l5.741,10.489a16.345,16.345,0,0,1-21.9-15.362Zm4.753-.74h5.116v4.346H91.7Zm6.383,4.346v-4.346h1.95l2.379,4.346Zm0,0" transform="translate(-9.458 92.396)" :fill="color" stroke="rgba(0,0,0,0)" stroke-width="1"/>
  </g>
</svg>

</template>

<script>
export default {
  name: 'IconNoSmoke',
  props: {
    width: {
      type: Number,
      default: 43.646
    },
    height: {
      type: Number,
      default: 43.646
    },
    color: {
      type: String,
      default: 'var(--color__primary-light)'
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
