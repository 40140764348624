<template>
<svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 38.342 39.5">
  <path id="Path_4558" data-name="Path 4558" d="M44.29,37H37.832l-.039-18.481a.752.752,0,0,0-.752-.75h0a.752.752,0,0,0-.75.754L36.328,37H34.144l-.019-8.222a.752.752,0,0,0-.752-.75h0a.752.752,0,0,0-.75.754L32.64,37H30.814V27.043a4.126,4.126,0,0,0,3.8-4.062V15.693a8.163,8.163,0,0,0-.716-3.344,6.226,6.226,0,0,1,3.931,1.73,5.829,5.829,0,0,1,1.81,4.209l.014,4.28a.752.752,0,0,0,.752.75h0a.752.752,0,0,0,.75-.755l-.014-4.279A7.321,7.321,0,0,0,38.876,13a7.687,7.687,0,0,0-2.9-1.763,3.976,3.976,0,0,0-2.741-6.86h-.009a3.992,3.992,0,0,0-1.835.452.752.752,0,1,0,.7,1.333,2.447,2.447,0,0,1,1.141-.28,2.475,2.475,0,0,1,.011,4.95.75.75,0,0,0-.1.007l-.12.005a8.559,8.559,0,0,0-4.349-3.113A4.412,4.412,0,1,0,23,7.843a8.551,8.551,0,0,0-4.007,2.994c-.119-.005-.238-.008-.356-.008l-.168,0-.041,0a2.475,2.475,0,1,1,1.18-4.652.752.752,0,0,0,.718-1.322,3.978,3.978,0,0,0-4.511,6.5,7.491,7.491,0,0,0-4.859,6.95v4.265a.752.752,0,0,0,1.5,0V18.3a6.061,6.061,0,0,1,5.65-5.945,8.164,8.164,0,0,0-.713,3.337v7.288a4.129,4.129,0,0,0,3.929,4.071V37H19.275V28.775a.752.752,0,0,0-1.5,0V37H15.922V18.524a.752.752,0,1,0-1.5,0V37H8.452a.752.752,0,0,0,0,1.5H44.29a.752.752,0,1,0,0-1.5ZM22.86,4.412A2.908,2.908,0,1,1,25.768,7.32,2.911,2.911,0,0,1,22.86,4.412Zm7.2,10.782a.752.752,0,0,0-.752.752V37H26.631V26.533a.752.752,0,0,0-1.5,0V37h-2.3V15.947a.752.752,0,0,0-1.5,0v9.6A2.62,2.62,0,0,1,18.9,22.981V15.693a6.68,6.68,0,0,1,1.095-3.664,7.241,7.241,0,0,1,12.029.008,6.68,6.68,0,0,1,1.089,3.655v7.288a2.614,2.614,0,0,1-2.3,2.549V15.947A.752.752,0,0,0,30.062,15.194Z" transform="translate(-7.2 0.5)" :fill="color" stroke="rgba(0,0,0,0)" stroke-width="1"/>
</svg>
</template>

<script>
export default {
  name: 'IconNoSmoke',
  props: {
    width: {
      type: Number,
      default: 38.342
    },
    height: {
      type: Number,
      default: 39.5
    },
    color: {
      type: String,
      default: 'var(--color__primary-light)'
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
