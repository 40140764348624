<template>
<svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 16.3 14.264">
    <path id="FontAwsome_sort-amount-down_" data-name="FontAwsome sort-amount-down " d="M2298.966,381.226h-2.038a.51.51,0,0,0-.51.51v1.018a.51.51,0,0,0,.51.51h2.038a.51.51,0,0,0,.51-.51v-1.018a.51.51,0,0,0-.51-.51Zm-4.076-2.038h-1.528V369.51a.51.51,0,0,0-.51-.51h-1.018a.51.51,0,0,0-.51.51v9.679H2289.8a.51.51,0,0,0-.359.869l2.548,3.056a.509.509,0,0,0,.72,0l2.548-3.056a.511.511,0,0,0-.362-.869Zm8.151-6.114h-6.113a.51.51,0,0,0-.51.51V374.6a.51.51,0,0,0,.51.51h6.114a.51.51,0,0,0,.51-.509v-1.019a.51.51,0,0,0-.51-.509ZM2301,377.151h-4.075a.51.51,0,0,0-.51.51v1.018a.51.51,0,0,0,.51.51H2301a.51.51,0,0,0,.51-.51V377.66a.51.51,0,0,0-.51-.51Zm4.076-8.151h-8.151a.51.51,0,0,0-.51.51v1.018a.51.51,0,0,0,.51.51h8.151a.51.51,0,0,0,.51-.51V369.51A.51.51,0,0,0,2305.078,369Z" transform="translate(-2289.288 -369)" :fill="color" />
</svg>
</template>

<script>
export default {
  name: 'IconBar',
  props: {
    width: {
      type: Number,
      default: 16.3
    },
    height: {
      type: Number,
      default: 14.264
    },
    color: {
      type: String,
      default: '#fff'
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
