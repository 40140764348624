<template>
  <v-dialog
    v-model="isVisible"
    max-width="378"
    content-class="bg-silver text-center"
  >
    <div class="text-right">
      <button @click.stop="close" class="btn-close bg-primary">
        <div class="btn-close__icon" />
      </button>
    </div>
    <div class="px-6">
      <h2 class="mt-n7 mb-5 text-heading --prm-light">{{ $t('menu.main.vacancySearch') }}</h2>
      <p class="text-medium --w-bold --c-light-gray">{{ $t('text.roomType.select') }}</p>
      <v-row class="ma-0">
        <v-col cols="6" v-for="tag in tags" :key="tag">
          <v-checkbox
            v-model="selectedTags"
            :value="tag"
            :label="tag"
            color="primary"
            class="normal-label"
            hide-details
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-btn
        elevation="0"
        color="btn-grad--prm"
        @click="confirm"
        large
        rounded
        class="my-10"
        width="170"
      >
        {{ $t('buttons.completeSelection') }}
      </v-btn>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: 'RoomType',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    value: {
      type: Array,
      default: () => []
    }
  },
  async mounted () {
    await this.$doLoading(async () => {
      await this.$showGqlError(async () => {
        this.tags = await this.$store.dispatch('getSearchTags')
      })
    })
  },
  data () {
    return {
      tags: [],
      selectedTags: []
    }
  },
  computed: {
    isVisible: {
      get () {
        return this.visible
      },
      set (value) {
        if (!value) {
          this.$emit('close')
        }
      }
    }
  },
  methods: {
    confirm () {
      this.$emit('confirm', this.selectedTags.filter(t => !!t))
    },
    close () {
      this.selectedTags = this.value
      this.$emit('close')
    }
  }
}
</script>
