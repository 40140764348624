<template>
  <div>
    <Breadcrumbs>
      {{ $route.meta.title }}
    </Breadcrumbs>
    <div class="form-container">
      <div class="bg-white pa-5">
        <v-card class="rounded-lg">
          <v-row class="ma-0">
            <v-col cols="4" class="text-title bg-primary --white rounded-lg rounded-r-0">
              {{ $t('common.searchCriteria') }}
            </v-col>
            <v-col class="pl-5">
              <ul class="text-title --w-medium">
                <li>
                  {{ checkInDate | jaShortDate }}-{{ checkOutDate | jaShortDate }}
                </li>
                <li>{{ selectedFacilities }}</li>
                <li v-if="tags.length">
                  {{ tags.join(', ') }}
                </li>
                <li>
                  {{ numberOfAdults + numberOfChildren + numberOfBedShare }}{{ $t('common.peopleUse') }}
                </li>
              </ul>
              <v-btn
                elevation="0"
                class="mt-3 bg-white border-bold --border-silver"
                @click="isVisibleSearchModal = true"
                rounded
                large
              >
                <IconSearch />
                <span class="ml-2 --gray-dark --w-bold">{{ $t('buttons.changeTheCondition') }}</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
        <div class="triangle-down"></div>
      </div>
      <div class="bg-orange--grad-reverse pa-4 pb-10">
        <h4 class="text-medium --white mb-2">
          {{ $t('common.searchResult') }}
          <span class="text-heading --w-bold">{{ numberSearchResult }}</span>
          {{ $t('common.facility') }}
        </h4>
        <label class="text-default --white">
          <IconBar class="mr-2" />
          <span>{{ $t('common.reordering') }}</span>
        </label>
        <v-btn
          elevation="0"
          class="border-medium --border-white --white ml-4"
          color="bg-orange--grad-reverse"
          @click="reversed = !reversed"
        >
          {{ reversed ? $t('filter.inOrderOfHighestPrice') : $t('filter.inOrderOfLowestPrice') }}
        </v-btn>
        <VacancyItem
          v-for="f in availableFacilities"
          :key="f.id"
          :facilityId="f.id"
          :address="f.address1"
          :imageUrl="f.mainImageUrl"
          :facilityHpUrl="f.facilityHpUrl"
          :name="f.name"
          :tags="f.tags"
          :priceFrom="f.priceFrom"
          :unit="unit"
        />
      </div>
    </div>
    <SearchModal
      :visible="isVisibleSearchModal"
      @close="isVisibleSearchModal = false"
      @search="search"
    />
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs'
import IconSearch from '@/components/icons/IconSearch'
import IconBar from '@/components/icons/IconBar'
import VacancyItem from '@/components/VacancyItem'
import SearchModal from '@/views/VacancySearch/components/SearchModal'
import mixin from './searchVacancyMixin'

export default {
  name: 'Reservation',
  mixins: [mixin],
  components: {
    Breadcrumbs,
    IconSearch,
    IconBar,
    VacancyItem,
    SearchModal
  },
  async mounted () {
    await this.search()
  },
  data () {
    return {
      isVisibleSearchModal: false,
      reversed: false
    }
  },
  computed: {
    availableFacilities () {
      return this.$store.getters.getAvailableFacilities(this.reversed)
    },
    unit () {
      return this.$store.getters.newBookingAccommodationPriceUnit()
    },
    remainingPoint () {
      return this.$store.getters.remainingPointsForBooking
    },
    selectedFacilities () {
      if (!this.facilities || this.facilities.length === 0) {
        return this.$t('booking.allFacilities')
      } else {
        return this.facilities.map(f => f.shortName).join(', ')
      }
    },
    numberSearchResult () {
      return this.availableFacilities.length
    }
  },
  methods: {
    async search () {
      this.isVisibleSearchModal = false
      await this.$doLoading(async () => {
        await this.$showGqlError(async () => {
          await this.$store.dispatch('searchVacancy')
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.triangle-down {
  width: 0;
  height: 0;
  margin: 0 auto;
  border-left: 55px solid transparent;
  border-right: 55px solid transparent;
  border-top: 20px solid var(--color__primary);
}
</style>
